import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { Company } from 'src/types';
import * as fromCompanies from '../actions/company.actions';
import { CompanyService } from '../services/company.service';
import { MatDialog } from '@angular/material/dialog';

@Injectable({ providedIn: 'root' })
export class CompanyEffects {
  constructor(
    private actions$: Actions,
    private companyService: CompanyService,
    private toastr: ToastrService,
    private _dialog: MatDialog,
    private store: Store<any>,
  ) {}

  loadCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromCompanies.loadCompanies,
        fromCompanies.createCompanySuccess,
        fromCompanies.updateCompanySuccess,
        fromCompanies.deleteCompanySuccess,
      ),
      switchMap(() =>
        this.companyService.loadCompanies().pipe(
          map(({ body: { complete: companies } }) => fromCompanies.loadCompaniesSuccess({ companies })),
          catchError((error) => of(fromCompanies.loadCompaniesFailure({ error }))),
        ),
      ),
    ),
  );

  createCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCompanies.createCompany),
      switchMap((company) =>
        this.companyService.createCompany(company).pipe(
          map((response) => fromCompanies.createCompanySuccess({ response })),
          catchError((error) => of(fromCompanies.createCompanyFailure({ error }))),
        ),
      ),
    ),
  );

  updateCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCompanies.updateCompany),
      switchMap((company) =>
        this.companyService.updateCompany(company).pipe(
          map((response) => fromCompanies.updateCompanySuccess({ response })),
          catchError((error) => of(fromCompanies.updateCompanyFailure({ error }))),
        ),
      ),
    ),
  );

  deleteCompany$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromCompanies.deleteCompany),
      switchMap(({ uuid }) =>
        this.companyService.deleteCompany(uuid).pipe(
          map((response) => fromCompanies.deleteCompanySuccess({ response })),
          catchError((error) => of(fromCompanies.deleteCompanyFailure({ error }))),
        ),
      ),
    ),
  );

  openDeleteVoyagePrompt$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromCompanies.openDeleteCompanyPrompt),
        tap((company: Company) => {
          const { uuid, legalName, name, created, createdBy, updated, updatedBy } = company;

          const createdDate = typeof created === 'string' ? new Date(created).toDateString() : created.toDateString();
          const updatedDate = typeof updated === 'string' ? new Date(updated).toDateString() : updated.toDateString();

          const message = `
          <p>Are you sure you want to delete the following company?</p>
          <p><span class="color-light">Company ID:</span> ${uuid}</p>
          <p><span class="color-light">Legal Name:</span> ${legalName}</p>
          <p><span class="color-light">Display Name:</span> ${name}</p>
          <p><span class="color-light">Created:</span> ${createdDate}</p>
          <p><span class="color-light">Created By:</span> ${createdBy}</p>
          <p><span class="color-light">Updated:</span> ${updatedDate}</p>
          <p><span class="color-light">Updated By:</span> ${updatedBy}</p>
        `;
          this._dialog
            .open(ConfirmationDialogComponent, {
              disableClose: true,
              autoFocus: false,
              panelClass: 'sc-dialog',
              width: '600px',
              height: '400px',
              data: { title: 'Confirm delete company', message, btnOkText: 'Delete Company' },
            })
            .afterClosed()
            .subscribe((data: boolean) => {
              if (data) {
                this.store.dispatch(fromCompanies.deleteCompany({ uuid }));
              }
            });
        }),
      ),
    { dispatch: false },
  );

  success$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          fromCompanies.updateCompanySuccess,
          fromCompanies.createCompanySuccess,
          fromCompanies.deleteCompanySuccess,
        ),
        tap(({ type }) => this.toastr.success('The operation was successful!', type, { timeOut: 3000 })),
      ),
    { dispatch: false },
  );

  error$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          fromCompanies.updateCompanyFailure,
          fromCompanies.createCompanyFailure,
          fromCompanies.deleteCompanyFailure,
          fromCompanies.loadCompaniesFailure,
        ),
        tap(({ type, error: { error } }) => {
          const message = error?.errorDetail ? error.errorDetail : 'Something went wrong';
          this.toastr.error(message, type, { timeOut: 5000 });
        }),
      ),
    { dispatch: false },
  );
}
