import {
  Anomaly,
  BackendReport,
  Bunker,
  CIICorrectionFactor,
  Port,
  Report,
  ReportBunkers,
  ReportFormInterface,
} from 'src/types';
import { getTimezoneOffset, typedKeys } from '../conversion.utils';
import { anomaliesTypes, bunkerTypes } from 'src/directories';
import { compareDate } from '../sort.utils';
import moment from 'moment';
import packageJson from '../../../package.json';

export const getPort = (ports: Map<string, Port> = new Map(), portCode: string) => {
  // TODO: This is only to fix the ports problem. Change to ports.get(port) once ports are standardized in backend
  if (!portCode || !ports.size) {
    return null;
  }

  const portCodeSubstring = portCode.substring(0, 5);
  return ports?.get(portCodeSubstring);
};

export const getPortName = (ports: Map<string, Port>, portCode: string) => {
  const port = ports && getPort(ports, portCode);
  return port ? port.name : portCode || '';
};

export const convertToReportForm = (report: Report, ports: Map<string, Port>) => {
  if (!report) {
    return null;
  }

  const {
    reportedTimeLocal,
    utcOffset,
    port: portValue,
    bunkers: reportBunkers,
    speedOverGroundKT: sailingSpeed,
    nextCargoPort: nextPort,
    previousCargoPort: previousPort,
    latitude: lat,
    longitude: long,
    anomalies,
  } = report;
  const reportDate = reportedTimeLocal.toString().slice(0, 10);
  const port = getPort(ports, portValue);
  const nextCargoPort = getPort(ports, nextPort);
  const previousCargoPort = getPort(ports, previousPort);
  const timeStartIndex = reportedTimeLocal.toString().indexOf('T') + 1;
  const timeEndIndex = timeStartIndex + 5;
  const localTime = reportedTimeLocal.toString().slice(timeStartIndex, timeEndIndex);
  const speedOverGroundKT = sailingSpeed === -1 ? null : sailingSpeed;
  const latitude = !!lat || lat === 0 ? Number(lat.toFixed(5)) : null;
  const longitude = !!long || long === 0 ? Number(long.toFixed(5)) : null;
  const timezone = getTimezoneOffset(utcOffset);

  const bunkers: Bunker[] = [];
  Object.keys(reportBunkers).forEach((grade) => {
    const {
      consumptionMT,
      remainingOnBoardMT,
      receivedMT,
      calculatedConsumptionMT,
      previousROB,
      previousCons,
      ...rest
    } = reportBunkers[grade];

    bunkers.push({
      ...rest,
      grade,
      consumptionMT,
      remainingOnBoardMT,
      receivedMT,
      calculatedConsumptionMT,
      previousROB,
      previousCons,
    });
  });

  return {
    ...report,
    reportDate,
    timezone,
    localTime,
    port,
    bunkers,
    speedOverGroundKT,
    latitude,
    longitude,
    nextCargoPort,
    previousCargoPort,
    anomalies,
  } as ReportFormInterface;
};

export const convertToBackendReport = (
  report: ReportFormInterface,
  source: 'siglar-reports-admin' | 'csv-import' = 'siglar-reports-admin',
): Partial<BackendReport> => {
  const {
    id,
    bunkers: reportBunkers = [],
    reportDate,
    localTime,
    port,
    timezone,
    nextCargoPort,
    previousCargoPort,
    utcOffset,
  } = report;

  const reportedTime =
    typeof reportDate === 'string' && reportDate.length > 10 ? reportDate : `${reportDate}T${localTime}:00${timezone}`;

  const filteredReport = typedKeys(report)
    .filter((key) => report[key] === 0 || key === 'remark' || !!report[key])
    .filter((key) => key !== 'reportDate')
    .reduce((accum, key) => ({ ...accum, [key]: report[key] }), {});

  const bunkers = reportBunkers.map((bunker) => {
    const {
      id: reportBunkerID = null,
      coolingKWH,
      coolingSFOCGKWH,
      dischargeBoilerMT,
      dischargeKWH,
      dischargeSFOCGKWH,
      dischargeStandaloneMT,
      heatingBoilerMT,
      blend,
      bdnDate,
      bdnFuelType,
      bdnNumber,
      bdnUtcOffset,
      bdnUUID,
    } = bunker;
    const grade = bunker.grade.includes('blend') ? 'blend' : bunker.grade;

    const ciiCorrectionFactor = {
      id: bunker.ciiCfId || null,
      reportBunkerID,
      grade,
      coolingKWH,
      coolingSFOCGKWH,
      dischargeBoilerMT,
      dischargeKWH,
      dischargeSFOCGKWH,
      dischargeStandaloneMT,
      heatingBoilerMT: convertToNumber(heatingBoilerMT, true),
      reportId: Number(id),
    };
    const { ciiCfId: _, ...bunkerWithoutCiiCfId } = bunker;
    const bdn = {
      bdnDate,
      bdnFuelType,
      bdnNumber,
      bdnUtcOffset,
      uuid: bdnUUID,
    };
    return { ...bunkerWithoutCiiCfId, bdn, blend: { ...blend, bdn }, ciiCorrectionFactor, grade, reportId: Number(id) };
  });
  const newReport: Partial<BackendReport> = {
    ...filteredReport,
    id: Number(id),
    bunkers,
    reportedTime,
    utcOffset: report?.utcOffset ? utcOffset : timezone,
    source: report?.source || source,
  };

  newReport.port = port ? port.unlocode : undefined;
  newReport.nextCargoPort = nextCargoPort ? nextCargoPort.unlocode : undefined;
  newReport.previousCargoPort = previousCargoPort ? previousCargoPort.unlocode : undefined;
  newReport.sourceVersion = packageJson.version;
  return newReport;
};

export const convertToBulkBackendReport = (
  report: Partial<ReportFormInterface>,
  source: 'siglar-reports-admin' | 'csv-import' = 'csv-import',
): Partial<BackendReport> => {
  const {
    bunkers = [],
    reportType,
    reportDate,
    localTime,
    utcOffset,
    cargoQuantityMT,
    port,
    nextCargoPort,
    previousCargoPort,
    timezone,
  } = report;
  const reportedTime =
    typeof reportDate === 'string' && reportDate.length > 10 ? reportDate : `${reportDate}T${localTime}:00${utcOffset}`;

  const altReportedTime =
    typeof reportDate === 'string' && reportDate.length > 10 ? reportDate : `${reportDate}T${localTime}:00${timezone}`;

  const filteredReport = typedKeys(report)
    .filter((key) => report[key] === 0 || key === 'remark' || !!report[key])
    .filter((key) => key !== 'reportDate')
    .filter((key) => key !== 'id')
    .reduce((accum, key) => ({ ...accum, [key]: report[key] }), {});

  const newReport: Partial<BackendReport> = {
    ...filteredReport,
    bunkers,
    condition: reportType === 'cast_off' || reportType === 'cosp' ? (cargoQuantityMT > 0 ? 'laden' : 'ballast') : null,
    reportedTime: report?.utcOffset ? reportedTime : altReportedTime,
    utcOffset: report?.utcOffset ? utcOffset : timezone,
    source,
  };
  newReport.port = port ? port.unlocode : undefined;
  newReport.nextCargoPort = nextCargoPort ? nextCargoPort.unlocode : undefined;
  newReport.previousCargoPort = previousCargoPort ? previousCargoPort.unlocode : undefined;
  newReport.sourceVersion = packageJson.version;
  return newReport;
};

export const convertToBulkEditBackendReport = (report: Partial<ReportFormInterface>): Partial<BackendReport> => {
  const {
    id,
    bunkers: reportBunkers = [],
    reportDate,
    localTime,
    utcOffset,
    port,
    nextCargoPort,
    previousCargoPort,
    timezone,
    source,
  } = report;

  const reportedTime =
    typeof reportDate === 'string' && reportDate.length > 10 ? reportDate : `${reportDate}T${localTime}:00${utcOffset}`;

  const altReportedTime =
    typeof reportDate === 'string' && reportDate.length > 10 ? reportDate : `${reportDate}T${localTime}:00${timezone}`;

  const filteredReport = typedKeys(report)
    .filter((key) => report[key] === 0 || key === 'remark' || !!report[key])
    .filter((key) => key !== 'reportDate')
    .filter((key) => key !== 'id')
    .reduce((accum, key) => ({ ...accum, [key]: report[key] }), {});

  const bunkers = reportBunkers
    .reduce((allBunkers, current) => {
      // Check for duplicate bunker grade
      const existingBunker = allBunkers.find((item) => item.grade === current.grade);

      if (existingBunker) {
        // If found, merge each numeric property
        (Object.keys(current) as (keyof Bunker)[]).forEach((key) => {
          if (typeof current[key] === 'number' && key !== 'id' && key !== 'reportId') {
            existingBunker[key] += Number(current[key]);
          }
        });
      } else {
        // If not found, push a new object
        allBunkers.push({ ...current });
      }

      return allBunkers;
    }, [])
    .map((bunker) => {
      const {
        coolingKWH,
        coolingSFOCGKWH,
        dischargeBoilerMT,
        dischargeKWH,
        dischargeSFOCGKWH,
        dischargeStandaloneMT,
        heatingBoilerMT,
        ciiCfId = null,
        id: reportBunkerID = null,
      } = bunker;

      const grade = bunker.grade.includes('blend') ? 'blend' : bunker.grade;

      const ciiCorrectionFactor: CIICorrectionFactor = {
        id: ciiCfId,
        reportBunkerID,
        grade,
        coolingKWH,
        coolingSFOCGKWH,
        dischargeBoilerMT,
        dischargeKWH,
        dischargeSFOCGKWH,
        dischargeStandaloneMT,
        heatingBoilerMT,
        reportId: Number(id),
      };
      const { ciiCfId: _, ...bunkerWithoutCiiCfId } = bunker;
      return { ...bunkerWithoutCiiCfId, grade, reportId: Number(id), ciiCorrectionFactor };
    });

  const newReport: Partial<BackendReport> = {
    ...filteredReport,
    bunkers,
    reportedTime: report?.utcOffset ? reportedTime : altReportedTime,
    utcOffset: report?.utcOffset ? utcOffset : timezone,
    source,
  };
  newReport.port = port ? port.unlocode : undefined;
  newReport.nextCargoPort = nextCargoPort ? nextCargoPort.unlocode : undefined;
  newReport.previousCargoPort = previousCargoPort ? previousCargoPort.unlocode : undefined;
  newReport.sourceVersion = packageJson.version;
  return newReport;
};

export const getCurrentBunkerTypes = (reports: Partial<Report>[]) =>
  reports
    .reduce((allBunkers, { bunkers }) => [...allBunkers, ...typedKeys(bunkers)], [])
    .reduce((uniqueKeys: any, key: string) => (uniqueKeys.includes(key) ? uniqueKeys : [...uniqueKeys, key]), [])
    .map((bunker: string) => (bunker ? bunker : 'INVALID'))
    .sort((a: any, b: any) => bunkerTypes.indexOf(a) - bunkerTypes.indexOf(b));

export const convertToNumber = (value: number | string, required = false) => {
  if (!value && value !== 0) {
    return required ? 0 : null;
  }

  if (value === '0,00' || value === '0') {
    return 0;
  }

  if (typeof value === 'number') {
    return value;
  }

  const formattedValue = value.replace(',', '.');
  return Number(formattedValue) || null;
};

export const toLocalTime = (bdnDate: string, bdnUtcOffset: string) => {
  const [hours, minutes] = bdnDate.substring(11, 16).split(':').map(Number);
  const [offsetHours, offsetMinutes] = bdnUtcOffset.split(':').map(Number);
  const date: Date = new Date(bdnDate);
  const currentOffset = date.getTimezoneOffset();
  const targetOffset = offsetHours * 60 + offsetMinutes;
  date.setHours(hours);
  date.setMinutes(minutes);
  const utcTime = date.getTime() + (targetOffset - currentOffset) * 60000;
  date.setTime(utcTime);
  const localTime = date.toISOString();
  return localTime;
};

export const toStandardTime = (bdnDate: string, bdnUtcOffset: string) => {
  const [hours, minutes] = bdnDate.substring(11, 16).split(':').map(Number);
  const [offsetHours, offsetMinutes] = bdnUtcOffset.split(':').map(Number);
  const date: Date = new Date(bdnDate);
  const currentOffset = date.getTimezoneOffset();
  const targetOffset = offsetHours * 60 + offsetMinutes;
  date.setHours(hours);
  date.setMinutes(minutes);
  const utcTime = date.getTime() - (targetOffset + currentOffset) * 60000;
  date.setTime(utcTime);
  const localTime = date.toISOString();
  return localTime;
};

export const getStructuredReport = (entries: BackendReport[] = []) => {
  const reports = [];
  let lastNoonReportTime = null;
  const clonedEntries = [...entries].sort((a, b) =>
    compareDate(new Date(a.reportedTime), new Date(b.reportedTime), true),
  );
  for (let idx = 0; idx < clonedEntries.length; idx++) {
    const report = clonedEntries[idx];
    const first = idx === 0;
    const bunkers: ReportBunkers = {};
    const { bunkers: previousBunkers } = first ? { bunkers: undefined } : clonedEntries[idx - 1];
    const previousReport = first ? { reportedTimeLocal: '', bunkers, reportType: '' } : clonedEntries[idx - 1];
    let warnings = { ...report.qa.warnings };
    report.bunkers?.forEach(
      ({ remainingOnBoardMT = 0, consumptionMT = 0, receivedMT = 0, grade, bdn, ciiCorrectionFactor, ...rest }) => {
        if (grade === 'blend' && rest.blend?.uuid) {
          grade = `blend_${rest.blend.uuid.slice(-5)}`;
        }
        const previousBunkerValue =
          previousBunkers &&
          previousBunkers.find(({ grade: previousGrade, ...otherFields }: Bunker) =>
            previousGrade === 'blend' && otherFields.blend?.uuid
              ? `blend_${otherFields.blend.uuid.slice(-5)}` === grade
              : previousGrade === grade,
          );

        let calculatedConsumptionMT = 0;
        if (!first && previousBunkerValue && typeof previousBunkerValue.remainingOnBoardMT === 'number') {
          calculatedConsumptionMT = Number(
            (previousBunkerValue.remainingOnBoardMT + receivedMT - remainingOnBoardMT)?.toFixed(3),
          );
        }

        bunkers[grade] = {
          ...rest,
          grade,
          bdn,
          bdnTimeLocal: bdn?.bdnDate && bdn?.bdnUtcOffset ? toLocalTime(bdn?.bdnDate, bdn?.bdnUtcOffset) : null,
          coolingKWH: ciiCorrectionFactor?.coolingKWH,
          coolingSFOCGKWH: ciiCorrectionFactor?.coolingSFOCGKWH,
          dischargeBoilerMT: ciiCorrectionFactor?.dischargeBoilerMT,
          dischargeKWH: ciiCorrectionFactor?.dischargeKWH,
          dischargeSFOCGKWH: ciiCorrectionFactor?.dischargeSFOCGKWH,
          dischargeStandaloneMT: ciiCorrectionFactor?.dischargeStandaloneMT,
          heatingBoilerMT: ciiCorrectionFactor?.heatingBoilerMT,
          ciiCfId: ciiCorrectionFactor?.id,
          remainingOnBoardMT: Number(remainingOnBoardMT?.toFixed(3)),
          consumptionMT: Number(consumptionMT?.toFixed(3)),
          calculatedConsumptionMT,
          receivedMT: receivedMT || 0,
          previousROB: Number(previousBunkerValue?.remainingOnBoardMT?.toFixed(3)) || 0,
          previousCons: Number(previousBunkerValue?.consumptionMT?.toFixed(3)) || 0,
          previousRcv: Number(previousBunkerValue?.receivedMT?.toFixed(3)) || 0,
        };
      },
    );

    const cargoQuantityMT =
      typeof report.cargoQuantityMT === 'number' ? Number(report.cargoQuantityMT.toFixed(3)) : report.cargoQuantityMT;

    const sailedDistanceNm =
      typeof report.sailedDistanceNm === 'number'
        ? Number(report.sailedDistanceNm.toFixed(3))
        : report.sailedDistanceNm;

    let hasMissingReport = false;
    let reportedTimeLocal;
    if (!first) {
      const isConsecutiveNoon = report.reportType === 'noon' && previousReport.reportType === 'noon';
      const lastNoonReportDate = moment(lastNoonReportTime);
      const previousDate = moment(previousReport.reportedTimeLocal);
      const currentDate = moment(report.reportedTimeLocal);
      hasMissingReport =
        currentDate.diff(previousDate, 'days') > 1 ||
        (currentDate.diff(lastNoonReportDate, 'hours') > 24 &&
          currentDate.diff(previousDate, 'days', true) > 1 &&
          !isConsecutiveNoon);
    }

    if (report.reportType === 'noon' || String(report.reportedTimeLocal).slice(11, 16) === '12:00') {
      lastNoonReportTime = report.reportedTimeLocal;
    }

    if (hasMissingReport) {
      const missingReport = {
        id: report.id * 1000,
        reportedTimeLocal,
        bunkers: [],
        qa: {
          warnings: { hasMissingReport },
          errors: {},
        },
        hasMissingReport,
      } as unknown as Report;
      reports.push(missingReport);
    }

    const timeElapsed = Number(report.timeSinceLastReport);
    const startTime = moment(previousReport.reportedTimeLocal);
    const endTime = moment(report.reportedTimeLocal);
    const duration = endTime.diff(startTime, 'hours', true);

    if (timeElapsed) {
      warnings = timeElapsed - duration > 1 ? { ...warnings, timeElapsedGreaterThanDuration: true } : warnings;
      warnings = timeElapsed - duration < -1 ? { ...warnings, timeElapsedLessThanDuration: true } : warnings;
    }

    if (sailedDistanceNm) {
      warnings = sailedDistanceNm / duration > 22 ? { ...warnings, speedAbove22KNOnReport: true } : warnings;
    }

    const anomalies = anomaliesTypes
      .map(({ value: type }) =>
        [...report.anomalies]
          .map(
            (anomaly) =>
              ({
                ...anomaly,
                anomalyTypeId: anomaly.anomalyTypeId.replace('high_', '').replace('low_', ''),
              }) as Anomaly,
          )
          .sort(({ created: a }, { created: b }) => compareDate(a as Date, b as Date, false))
          .find((anomaly) => anomaly.anomalyTypeId === type),
      )
      .filter(Boolean);
    const hasKnutsenWarning = report?.externalAttributes?.knutsenLoadingDischargingWarning;
    warnings = hasKnutsenWarning ? { ...warnings, hasKnutsenWarning } : warnings;
    reports.push({ ...report, bunkers, anomalies, cargoQuantityMT, sailedDistanceNm, qa: { ...report.qa, warnings } });
  }
  return reports;
};
