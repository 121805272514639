import { PortActivity, ShipCondition } from 'src/types';
import moment from 'moment';

export const getTimeAgo = (date: Date | string) => {
  const suffix = moment(date).format('DD. MMMM YYYY');

  if (date) {
    const now = new Date();
    const minutesDiff = moment(now).diff(date, 'minutes');
    const hoursDiff = moment(now).diff(date, 'hours');
    const daysDiff = moment(now).diff(date, 'days');

    return minutesDiff < 60
      ? `${minutesDiff} minutes ago (${suffix})`
      : hoursDiff < 24
        ? `${hoursDiff} hours ago (${suffix})`
        : `${daysDiff} days ago (${suffix})`;
  }

  return suffix;
};

export const getTimeAgoForComment = (date: Date | string) => {
  const suffix = moment(date).format('DD MMM YYYY');

  if (date) {
    const now = new Date();
    const minutesDiff = moment(now).diff(date, 'minutes');
    const hoursDiff = moment(now).diff(date, 'hours');
    const daysDiff = moment(now).diff(date, 'days');

    if (daysDiff <= 3) {
      return minutesDiff < 60
        ? `${minutesDiff} minutes ago (${suffix})`
        : hoursDiff < 24
          ? `${hoursDiff} hours ago (${suffix})`
          : `${daysDiff} days ago (${suffix})`;
    } else {
      return suffix;
    }
  }

  return suffix;
};

export const formatConditionLabel = (condition: ShipCondition | PortActivity) => {
  switch (condition) {
    case 'ballast': {
      return 'Ballast leg';
    }
    case 'bunkering': {
      return 'Bunkering';
    }
    case 'discharging': {
      return 'Discharge port';
    }
    case 'idle': {
      return 'Idle/anchorage';
    }
    case 'laden': {
      return 'Laden leg';
    }
    case 'loading': {
      return 'Load port';
    }
    case 'repair': {
      return 'Repair';
    }
    case 'drydock': {
      return 'Drydock';
    }
    case 'canal_transit': {
      return 'Canal Transit';
    }
    case 'other': {
      return 'Other/canal';
    }
    case 'awaiting_loading': {
      return 'Awaiting loading';
    }
    case 'awaiting_discharging': {
      return 'Awaiting discharging';
    }
    case 'W_M_after': {
      return 'Awaiting sailing';
    }

    default: {
      return '(unknown)';
    }
  }
};

/** Used for chart labels for the purpose of making them multiline.
 * Chart.js multiline labels are structured with as an array i.e. ['Line 1', 'Line 2'] */
export const formatMultilineActivityLabel = (
  activity: PortActivity | ShipCondition,
  portMovement: string[],
  portName?: string,
): string[] => {
  const formatedPortName = portName ? ` (${portName})` : '';

  switch (activity) {
    case 'ballast': {
      return ['Ballast leg', ...portMovement];
    }
    case 'bunkering': {
      return [`Bunkering ${formatedPortName}`, ...portMovement];
    }
    case 'discharging': {
      return [`Discharge port ${formatedPortName}`, ...portMovement];
    }
    case 'idle': {
      return [`Idle/anchorage ${formatedPortName}`, ...portMovement];
    }
    case 'laden': {
      return ['Laden leg', ...portMovement];
    }
    case 'loading': {
      return [`Load port ${formatedPortName}`, ...portMovement];
    }
    case 'repair': {
      return [`Repair ${formatedPortName}`, ...portMovement];
    }
    case 'drydock': {
      return [`Drydock ${formatedPortName}`, ...portMovement];
    }
    case 'canal_transit': {
      return [`Canal Transit ${formatedPortName}`, ...portMovement];
    }
    case 'other': {
      return [`Other/canal ${formatedPortName}`, ...portMovement];
    }
    case 'awaiting_loading': {
      return [`Awaiting loading ${formatedPortName}`, ...portMovement];
    }
    case 'awaiting_discharging': {
      return [`Awaiting discharging ${formatedPortName}`, ...portMovement];
    }
    case 'awaiting_repair': {
      return [`Awaiting repair ${formatedPortName}`, ...portMovement];
    }
    case 'awaiting_bunkering': {
      return [`Awaiting bunkering ${formatedPortName}`, ...portMovement];
    }
    case 'awaiting_drydock': {
      return [`Awaiting drydock ${formatedPortName}`, ...portMovement];
    }
    case 'W_M_after': {
      return [`Awaiting sailing ${formatedPortName}`, ...portMovement];
    }

    default: {
      return ['(unknown)'];
    }
  }
};

/** Capitalizes first character of a string (not every word) */
export const capitalize = (term = '') => `${term.charAt(0).toUpperCase()}${term.substring(1)}`;

// eslint-disable-next-line no-shadow
export enum QaLabel {
  missingStartReport = 'Missing start report',
  noReports = 'No reports',
  startReportNotCOSP = 'Start report not COSP',
  endReportNotCOSP = 'End report not COSP',
  missingEmissionFactor = 'Missing emission factor',
  shipNotFound = 'Ship not found',
  missingCPDate = 'Missing CP date',
  missingEndReport = 'Missing end report',
  missingNoonReport = 'Missing NOON report',
  missingEOSPReport = 'Missing EOSP report',
  duplicateReports = 'Duplicate reports',
  cargoQtySmall = 'Cargo quantity is small',
  noDischargingPort = 'No discharging port',
  noLoadingPort = 'No loading port',
  noIMOConnected = 'No IMO connected',
  lastReportOlderThan24hrs = 'Last report older than 24 hours',
  statusNotDelivered = 'Status not delivered',
  missingEVDIorEEDIorEVI = 'Missing EVDI, EEDI or EVI',
  missingShipType = 'Missing ship type',
  missingDraught = 'Missing ship draught',
  missingSpeed = 'Missing ship speed',
  missingBuiltYear = 'Missing ship built year',
  missingDWT = 'Missing ship DWT',
  missingCBM = 'Missing ship CBM',
  missingLDT = 'Missing ship LDT',
  missingEVDI = 'Missing ship EVDI',
  portDistanceNotFound = 'Port distance not found',
  mismatchedReportPairTypes = 'Mismatched report pair types',
  noonReportsShorterThan16hrs = 'NOON reports shorter than 16 hours',
  noReportsFor32hrs = 'No reports for 32 hours',
  conditionChanged = 'Condition changed',
  cargoQtyChanged = 'Cargo quantity changed',
  bunkerIncreased = 'Bunker increased',
  inconsistentROBandCons = 'Inconsistent ROB and CONS',
  portXXXXX = 'Port XXXX',
  highConsumption = 'High consumption',
  toPortFromPortMismatch = 'To port from port mismatch',
  startReportSTS = 'Start report STS',
  endReportSTS = 'End report STS',
  castOffWithSTS = 'CAST-OFF with STS',
  wrongStartReportType = 'Wrong start report type',
  wrongEndReportType = 'Wrong end report type',
  cargoQtyLargerThanShip = 'Cargo quantity larger than ship',
  missingReferenceDistance = 'Missing reference distance',
  missingAtLeastTwoCastOffReportsWithBallast = 'Missing atleast two cast off reports with condition ballast',
  missingAtLeastOneCastOffReportWithLaden = 'Missing atleast one cast off reports with condition laden',
  distanceReportedAISDeviation30pct = 'Reported distance has an AIS deviation of 30%',
  distanceReferenceAISDeviation30pct = 'Reference distance has an AIS deviation of 30%',
  distanceReferenceReportedDeviation30pct = 'Distance Deviation is over 30%',
  noReportsReceivedSince72hoursAgo = 'No reports received in 72 hours',
  secondBallastLegNoEndReport = 'No end report selected (Assumed ended)',
  startReportInUseByAnotherVoyage = 'Start report in use by another voyage',
  endReportInUseByAnotherVoyage = 'End report in use by another voyage',
  startReportInUseByAnotherVoyageInSameCompany = 'Start report in use by another voyage in the same company',
  endReportInUseByAnotherVoyageInSameCompany = 'End report in use by another voyage in the same company',
  reportSequenceIsNotLogical = 'Report sequence is not logical',
  oldAndNewReporting = 'Old and new reporting',
  missingSailedDistance = 'Missing sailed distance',
  hasMissingReport = 'Voyage has one or more missing reports',
  wrongNextCargoPort = 'Wrong next cargo port',
  wrongPreviousCargoPort = 'Wrong previous port',
  ballastLegCargoQty = 'Ballast leg with cargo quantity',
  ladenLegZeroCargoQty = 'Laden leg with zero cargo quantity',
  mismatchLocationAllFastCastOff = 'Mismatch location All Fast Cast Off',
  stsWithinUnofficialPort = 'STS within unofficial port',
  stsWithinUnofficialSTSArea = 'STS within unofficial STS Area',
  stsOutsideOfficialOffshoreField = 'STS outside official offshore field',
  mismatchConsumptionSplitAndConsumption = 'Consumption split by consumer does not match consumption',
  sailedDistanceExceeds600Nm = 'Sailed distance exceeds 600 nm',
  conditionBallastCargoQtyNotZero = 'Condition ballast but cargo qty_mt > 0',
  conditionBallastCargoQty3NotZero = 'Condition ballast but cargo qty_mt3 > 0',
  conditionLadenButCargoQtyIsZero = 'Condition cannot be laden when cargo quantity is 0',
  cargoQtyGreaterThanDeadWeightTon = 'Cargo quantity is greater than ship ship dead weight',
  timeAtAnchorGreaterThanTimeElapsed = 'Time at anchor is greater than time elapsed',
  timeDriftingGreaterThanTimeElapsed = 'Time drifting is greater than time elapsed',
  timeElapsedLessThanDuration = 'Reported time elapsed less than actual duration',
  timeElapsedGreaterThanDuration = 'Reported time elapsed greater than actual duration',
  speedAbove22KNOnReport = 'Speed above 22kn on report',
  sumTimeGreaterThanTimeElapsed = 'Sum of time drifting and time at anchor is greater than time elapsed',
  heatingConsumptionTooHigh = 'Consumption for cargo heating should not be greater than the total consumption',
  dischargeConsumptionTooHigh = 'Consumption for cargo discharge should not be greater than the total consumption',
  heatingAndDischargeConsumptionTooHigh = 'Consumption for cargo heating and discharge should not be greater than the total consumption',
  coolingConsumptionTooHigh = 'Consumption for cargo cooling should not be greater than the total consumption',
  coolingSFOCGKWHOutOfRange = 'Cooling SFOC should be in the range 150-300 g/kwh',
  dischargeSFOCGKWHOutOfRange = 'Discharge SFOC should be in the range 150-300 g/kwh',
  emptyCoolingKWH = 'coolingKWH value is empty',
  emptyCoolingSFOCGKWH = 'SFOC Cargo cooling/reliquefaction is empty',
  emptyHeatingBoilerMT = 'Consumption for cargo heating is empty',
  emptyDischargeBoilerMT = 'Consumption for cargo discharge pump is empty',
  emptyDischargeKWH = 'Electrical work cargo discharge pumps is empty',
  emptyDischargeSFOCGKWH = 'SFOC cargo discharge pumps is empty',
  emptyDischargeStandaloneMT = 'Consumption discharge pumps standalone engine is empty',
  port_distance = 'Port distance anomaly',
  consumption = 'Consumption anomaly',
  distance = 'Distance anomaly',
}

export const formatQaLabel = (error: keyof typeof QaLabel) => {
  if (!(QaLabel[error] as QaLabel)) {
    return error;
  }
  return QaLabel[error] as QaLabel;
};

export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const snakeToCamelCase = (str: string) =>
  str
    .toLowerCase()
    .replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) => (index === 0 ? letter.toLowerCase() : letter.toUpperCase()))
    .replace(/\s+/g, '');
