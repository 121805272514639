import { createAction, props } from '@ngrx/store';
import {
  FrontendVoyageComment,
  Voyage,
  VoyageAnalysis,
  VoyageAnalysisSelector,
  VoyageComment,
  VoyageFilters,
  VoyageForm,
  VoyageStatus,
  VoyageSummary,
  PreferredDistance,
  VoyageTag,
} from 'src/types';

// eslint-disable-next-line no-shadow
export const enum VoyageActions {
  LoadVoyagesList = '[VOYAGE] Load Voyages List',
  LoadVoyagesListSuccess = '[VOYAGE] Load Voyages List Success',
  LoadVoyagesListFailure = '[VOYAGE] Load Voyages List Failure',
  loadVoyageAnomalies = '[VOYAGE] Load Voyage Anomalies',
  loadVoyageAnomaliesSuccess = '[VOYAGE] Load Voyage Anomalies Success',
  loadVoyageAnomaliesFailure = '[VOYAGE] Load Voyage Anomalies Failure',
  LoadShipVoyages = '[VOYAGE] Load Ship Voyages',
  LoadShipVoyagesSuccess = '[VOYAGE] Load Ship Voyages Success',
  LoadShipVoyagesFailure = '[VOYAGE] Load Ship Voyages Failure',
  LoadVoyageDetails = '[VOYAGE] Load Voyage Details',
  LoadVoyageDetailsSuccess = '[VOYAGE] Load Voyage Details Success',
  LoadVoyageDetailsFailure = '[VOYAGE] Load Voyage Details Failure',
  LoadVoyageComments = '[VOYAGE] Load Voyage Comments',
  LoadVoyageCommentsSuccess = '[VOYAGE] Load Voyage Comments Success',
  LoadVoyageCommentsFailure = '[VOYAGE] Load Voyage Comments Failure',
  LoadVoyageSummary = '[VOYAGE] Load Voyage Summary',
  LoadVoyageSummarySuccess = '[VOYAGE] Load Voyage Summary Success',
  LoadVoyageSummaryFailure = '[VOYAGE] Load Voyage Summary Failure',
  CreateVoyage = '[VOYAGE] Create Voyage',
  CreateVoyageSuccess = '[VOYAGE] Create Voyage Success',
  CreateVoyageFailure = '[VOYAGE] Create Voyage Failure',
  ClearVoyageComments = '[VOYAGE] Clear Voyage Comments',
  ClearVoyageDetails = '[VOYAGE] Clear Voyage Details',
  UpdateVoyage = '[VOYAGE] Update Voyage',
  UpdateVoyageSuccess = '[VOYAGE] Update Voyage Success',
  UpdateVoyageFailure = '[VOYAGE] Update Voyage Failure',
  PatchVoyage = '[VOYAGE] Patch Voyage',
  PatchVoyageSuccess = '[VOYAGE] Patch Voyage Success',
  PatchVoyageFailure = '[VOYAGE] Patch Voyage Failure',
  AssignVoyage = '[VOYAGE] Assign Voyage',
  AssignVoyageSuccess = '[VOYAGE] Assign Voyage Success',
  ResetVoyageReportRange = '[VOYAGE] Reset Voyage Report',
  ResetVoyageReportRangeSuccess = '[VOYAGE] Reset Voyage Report Success',
  ResetVoyageReportRangeFailure = '[VOYAGE] Reset Voyage Report Failure',
  PatchMultipleVoyages = '[VOYAGE] Patch Multiple Voyages',
  PatchMultipleVoyageSuccess = '[VOYAGE] Patch Multiple Voyages Success',
  PatchMultipleVoyagesFailure = '[VOYAGE] Patch Multiple Voyages Failure',
  DeleteVoyage = '[VOYAGE] Delete Voyage',
  DeleteMultipleVoyages = '[VOYAGE] Delete Multiple Voyages',
  DeleteVoyageSuccess = '[VOYAGE] Delete Voyage Success',
  DeleteVoyageFailure = '[VOYAGE] Delete Voyage Failure',
  PatchPreferredVoyageDistance = '[VOYAGE] Patch Preffered Voyage Distance',
  PatchPreferredVoyageDistanceSuccess = '[VOYAGE] Patch Preffered Voyage Distance Success',
  PatchPreferredVoyageDistanceFailure = '[VOYAGE] Patch Preffered Voyage Distance Failure',
  AddVoyageComment = '[VOYAGE] Add Voyage Comment',
  AddVoyageCommentSuccess = '[VOYAGE] Add Voyage Comment Success',
  AddVoyageCommentFailure = '[VOYAGE] Add Voyage Comment Failure',
  DeleteVoyageComment = '[VOYAGE] Delete Voyage Comment',
  DeleteVoyageCommentSuccess = '[VOYAGE] Delete Voyage Comment Success',
  DeleteVoyageCommentFailure = '[VOYAGE] Delete Voyage Comment Failure',
  UpdateVoyageComment = '[VOYAGE] Update Voyage Comment',
  UpdateVoyageCommentSuccess = '[VOYAGE] Update Voyage Comment Success',
  UpdateVoyageCommentFailure = '[VOYAGE] Update Voyage Comment Failure',
  VerifyVoyage = '[VOYAGE] Verify Voyage',
  VerifyVoyageSuccess = '[VOYAGE] Verify Voyage Success',
  VerifyVoyageFailure = '[VOYAGE] Verify Voyage Failure',
  UnverifyVoyage = '[VOYAGE] Unverify Voyage',
  UnverifyVoyageSuccess = '[VOYAGE] Unverify Voyage Success',
  UnverifyVoyageFailure = '[VOYAGE] Unverify Voyage Failure',
  UpdateVoyageRemark = '[VOYAGE] Update Voyage Remark',
  SetSearchFilter = '[VOYAGE] Set Search Filter',
  ClearSearchFilter = '[VOYAGE] Clear Search Filter',
  AddToTagFilter = '[VOYAGE] Add to Tag Filter',
  RemoveFromTagFilter = '[VOYAGE] Remove from Tag Filter',
  ResetTagFilter = '[VOYAGE] Reset Tag Filter',
  SetViewFilter = '[VOYAGE] Set View Filter',
  SetCompanyFilter = '[VOYAGE] Set Company Filter',
  ToggleCheckVoyageUuid = '[VOYAGE] Toggle Check Voyage Uuid',
  SetCheckedVoyageUuids = '[VOYAGE] Set Checked Voyage Uuids',
  SetVoyageFilters = '[VOYAGE] Set Voyage Filters',
  SetStartReport = '[VOYAGE] Set Start Report',
  SetEndReport = '[VOYAGE] Set End Report',
  SetVoyageTags = '[VOYAGE] Set Voyage Tags',
  SetVoyageTagsSuccess = '[VOYAGE] Set Voyage Tags Success',
  SetVoyageVesselName = '[VOYAGE] Set Voyage Vessel Name',
  SetVoyageVesselNameSuccess = '[VOYAGE] Set Voyage Vessel Name Success',
  SetVoyageReportRangeSuccess = '[VOYAGE] Set Voyage Report Range Success',
  GetVoyageQuerySizeSuccess = '[VOYAGE] Get Voyage Query Size Success',
  PromptDeleteVoyage = '[VOYAGE] Prompt Delete Voyage',
  VoyageNoop = '[VOYAGE] Noop',
}

/** Load voyages List */
export const loadVoyagesList = createAction(VoyageActions.LoadVoyagesList, props<{ filters?: string }>());
export const loadVoyagesListSuccess = createAction(
  VoyageActions.LoadVoyagesListSuccess,
  props<{ voyages: any[]; count: number }>(),
);
export const loadVoyagesListFailure = createAction(VoyageActions.LoadVoyagesListFailure, props<{ error: any }>());

/** Load voyage anomalies */
export const loadVoyageAnomalies = createAction(VoyageActions.loadVoyageAnomalies);
export const loadVoyageAnomaliesSuccess = createAction(
  VoyageActions.loadVoyageAnomaliesSuccess,
  props<{ anomalies: any }>(),
);
export const loadVoyageAnomaliesFailure = createAction(
  VoyageActions.loadVoyageAnomaliesFailure,
  props<{ error: any }>(),
);

/** Load Ship voyages */
export const loadShipVoyages = createAction(VoyageActions.LoadShipVoyages, props<{ imo: number }>());
export const loadShipVoyagesSuccess = createAction(
  VoyageActions.LoadShipVoyagesSuccess,
  props<{ shipVoyages: Voyage[] }>(),
);
export const loadShipVoyagesFailure = createAction(VoyageActions.LoadShipVoyagesFailure, props<{ error: any }>());

/** Load voyage details */
export const loadVoyageDetails = createAction(VoyageActions.LoadVoyageDetails, props<{ uuid: string }>());
export const loadVoyageDetailsSuccess = createAction(
  VoyageActions.LoadVoyageDetailsSuccess,
  props<{ voyage: VoyageAnalysis }>(),
);
export const loadVoyageDetailsFailure = createAction(VoyageActions.LoadVoyageDetailsFailure, props<{ error: any }>());

/** Load voyage Summary */
export const loadVoyageSummarySuccess = createAction(
  VoyageActions.LoadVoyageSummarySuccess,
  props<{ voyageSummary: VoyageSummary }>(),
);
export const loadVoyageSummaryFailure = createAction(VoyageActions.LoadVoyageSummaryFailure, props<{ error: any }>());

/** Load voyage comments */
export const loadVoyageComments = createAction(VoyageActions.LoadVoyageComments, props<{ uuid: string }>());
export const loadVoyageCommentsSuccess = createAction(
  VoyageActions.LoadVoyageCommentsSuccess,
  props<{ comments: VoyageComment[] }>(),
);
export const loadVoyageCommentsFailure = createAction(VoyageActions.LoadVoyageCommentsFailure, props<{ error: any }>());

/** Clear voyage comments */
export const clearVoyageComments = createAction(VoyageActions.ClearVoyageComments);

/** Add voyage comment */
export const addVoyageComment = createAction(
  VoyageActions.AddVoyageComment,
  props<{ comment: string; uuid: string }>(),
);
export const addVoyageCommentSuccess = createAction(
  VoyageActions.AddVoyageCommentSuccess,
  props<{ comment: VoyageComment }>(),
);
export const addVoyageCommentFailure = createAction(VoyageActions.AddVoyageCommentFailure, props<{ error: any }>());

/** Delete voyage comment */
export const deleteVoyageComment = createAction(
  VoyageActions.DeleteVoyageComment,
  props<{ commentId: number; uuid: string }>(),
);

/** Patch voyage comment */

/** Update voyage comment */
export const updateVoyageComment = createAction(
  VoyageActions.UpdateVoyageComment,
  props<{ comment: FrontendVoyageComment; uuid: string }>(),
);
export const updateVoyageCommentSuccess = createAction(
  VoyageActions.UpdateVoyageCommentSuccess,
  props<{ comment: VoyageComment }>(),
);
export const updateVoyageCommentFailure = createAction(
  VoyageActions.UpdateVoyageCommentFailure,
  props<{ error: any }>(),
);

export const deleteVoyageCommentSuccess = createAction(
  VoyageActions.DeleteVoyageCommentSuccess,
  props<{ commentId: number }>(),
);
export const deleteVoyageCommentFailure = createAction(
  VoyageActions.DeleteVoyageCommentFailure,
  props<{ error: any }>(),
);

/** Create voyage */
export const createVoyage = createAction(VoyageActions.CreateVoyage, props<{ voyage: VoyageForm }>());
export const createVoyageSuccess = createAction(
  VoyageActions.CreateVoyageSuccess,
  props<{ voyage: VoyageForm; uuid: string }>(),
);
export const createVoyageFailure = createAction(VoyageActions.CreateVoyageFailure, props<{ error: any }>());

/** Update voyage */
export const updateVoyage = createAction(
  VoyageActions.UpdateVoyage,
  props<{ voyage: VoyageForm | Voyage; uuid: string }>(),
);
export const updateVoyageSuccess = createAction(VoyageActions.UpdateVoyageSuccess, props<{ voyage: Voyage }>());
export const updateVoyageFailure = createAction(VoyageActions.UpdateVoyageFailure, props<{ error: any }>());
export const updateVoyageRemark = createAction(
  VoyageActions.UpdateVoyageRemark,
  props<{ uuid: string; remark: string }>(),
);

/** Patch preffered voyage distance*/
export const patchPreferredVoyageDistance = createAction(
  VoyageActions.PatchPreferredVoyageDistance,
  props<{ voyage: VoyageAnalysisSelector; uuid: string; preferredDistance: Record<string, PreferredDistance> }>(),
);
export const patchPreferredVoyageDistanceSuccess = createAction(
  VoyageActions.PatchPreferredVoyageDistanceSuccess,
  props<{ voyage: Voyage; uuid: string; preferredDistance: Record<string, PreferredDistance> }>(),
);

export const patchPreferredVoyageDistanceFailure = createAction(
  VoyageActions.PatchPreferredVoyageDistanceFailure,
  props<{ error: any }>(),
);

/** Patch voyage(s) */
export const patchVoyage = createAction(VoyageActions.PatchVoyage, props<{ voyage: Voyage; uuid: string }>());
export const patchVoyageSuccess = createAction(
  VoyageActions.PatchVoyageSuccess,
  props<{ voyage: Voyage; uuid: string }>(),
);
export const patchVoyageFailure = createAction(VoyageActions.PatchVoyageFailure, props<{ error: any }>());
export const assignVoyage = createAction(VoyageActions.AssignVoyage, props<{ assignedTo: string; uuids: string }>());
export const assignVoyageSuccess = createAction(VoyageActions.AssignVoyageSuccess, props<{ uuid: string }>());
export const setVoyageTags = createAction(VoyageActions.SetVoyageTags, props<{ tags: VoyageTag[]; uuid: string }>());
export const setVoyageTagsSuccess = createAction(VoyageActions.SetVoyageTagsSuccess, props<{ uuid: string }>());
export const setVoyageVesselName = createAction(
  VoyageActions.SetVoyageVesselName,
  props<{ vesselName: string; uuid: string }>(),
);
export const setVoyageVesselNameSuccess = createAction(
  VoyageActions.SetVoyageVesselNameSuccess,
  props<{ uuid: string }>(),
);

/** Reset voyage(s) */
export const resetVoyageReportRange = createAction(
  VoyageActions.ResetVoyageReportRange,
  props<{ reportType: string; uuid: string }>(),
);
export const resetVoyageReportRangeSuccess = createAction(
  VoyageActions.ResetVoyageReportRangeSuccess,
  props<{ voyage: Voyage; uuid: string }>(),
);
export const resetVoyageReportRangeFailure = createAction(
  VoyageActions.ResetVoyageReportRangeFailure,
  props<{ error: any }>(),
);

/** Delete voyage */
export const deleteVoyage = createAction(VoyageActions.DeleteVoyage, props<{ uuid: string }>());
export const deleteMultipleVoyages = createAction(VoyageActions.DeleteMultipleVoyages, props<{ uuids: string[] }>());
export const deleteVoyageSuccess = createAction(VoyageActions.DeleteVoyageSuccess, props<{ uuid: string }>());
export const deleteVoyageFailure = createAction(VoyageActions.DeleteVoyageFailure, props<{ error: any }>());

/** Verify/unverify voyage */
export const verifyVoyage = createAction(VoyageActions.VerifyVoyage, props<{ uuid: string; remark?: string }>());
export const verifyVoyageSuccess = createAction(VoyageActions.VerifyVoyageSuccess, props<{ voyage: any }>());
export const verifyVoyageFailure = createAction(VoyageActions.VerifyVoyageFailure, props<{ error: any }>());
export const unverifyVoyage = createAction(VoyageActions.UnverifyVoyage, props<{ uuid: string; remark?: string }>());
export const unverifyVoyageSuccess = createAction(VoyageActions.UnverifyVoyageSuccess, props<{ uuid: string }>());
export const unverifyVoyageFailure = createAction(VoyageActions.UnverifyVoyageFailure, props<{ error: any }>());

/** Search filter */
export const setSearchFilter = createAction(VoyageActions.SetSearchFilter, props<{ searchValue: string }>());
export const clearSearchFilter = createAction(VoyageActions.ClearSearchFilter);
export const addToTagFilter = createAction(VoyageActions.AddToTagFilter, props<{ tag: string }>());
export const removeFromTagFilter = createAction(VoyageActions.RemoveFromTagFilter, props<{ tag: string }>());
export const resetTagFilter = createAction(VoyageActions.ResetTagFilter);
export const setViewFilter = createAction(VoyageActions.SetViewFilter, props<{ filter: VoyageStatus }>());
export const setCompanyFilter = createAction(VoyageActions.SetCompanyFilter, props<{ companyId: string | null }>());

/** Toggle check voyage uuid */
export const toggleCheckVoyageUuid = createAction(
  VoyageActions.ToggleCheckVoyageUuid,
  props<{ uuid: string; checked: boolean }>(),
);

export const setCheckedVoyageUuids = createAction(VoyageActions.SetCheckedVoyageUuids, props<{ uuids: string[] }>());

export const setVoyageFilters = createAction(
  VoyageActions.SetVoyageFilters,
  props<{ filters: Partial<VoyageFilters> }>(),
);

export const setStartReport = createAction(VoyageActions.SetStartReport, props<{ reportID: number; uuid: string }>());

export const setEndReport = createAction(VoyageActions.SetEndReport, props<{ reportID: number; uuid: string }>());

export const setVoyageReportRangeSuccess = createAction(
  VoyageActions.SetVoyageReportRangeSuccess,
  props<{ uuid: string }>(),
);

/** Noop */
export const voyageNoop = createAction(VoyageActions.VoyageNoop);
