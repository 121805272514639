import { ReportFormInterface } from 'src/types';

export const reportFields: { label: string; value: keyof ReportFormInterface }[] = [
  {
    label: 'Report type',
    value: 'reportType',
  },
  {
    label: 'Timezone',
    value: 'timezone',
  },
  {
    label: 'Local time',
    value: 'localTime',
  },
  {
    label: 'Location',
    value: 'location',
  },
  {
    label: 'Condition',
    value: 'condition',
  },
  {
    label: 'Port',
    value: 'port',
  },
  {
    label: 'Port activity',
    value: 'portActivity',
  },
  {
    label: 'Sailed distance (nm)',
    value: 'sailedDistanceNm',
  },
  {
    label: 'Sailed speed (KT)',
    value: 'speedOverGroundKT',
  },
  {
    label: 'Cargo quantity (MT)',
    value: 'cargoQuantityMT',
  },

  {
    label: 'Latitude',
    value: 'latitude',
  },
  {
    label: 'Longitude',
    value: 'longitude',
  },
  {
    label: 'Remark',
    value: 'remark',
  },
  {
    label: 'Internal remark',
    value: 'internalRemark',
  },
  {
    label: 'Source',
    value: 'source',
  },
];

export const bunkerTypes = [
  // 'hfo_hs',
  // 'hfo_vls',
  // 'lfo_vls',
  // 'lfo_uls',
  // 'mgo_uls',
  'hfo',
  'lfo',
  'mgo',
  'lng',
  'propane',
  'butane',
  'ethane',
  'methanol',
  'ulsfo',
  'vlsfo',
  'lsfo',
  'ifo',
  'lsmgo',
  'mdo',
];

export const estimationPrioritization = {
  consumption: ['ais', 'at_berth_sts', 'daily'],
  distance: ['ais', 'routing', 'haversine', 'projected'],
  port: ['port', 'ais', 'routing', 'haversine', 'projected'],
};

export const resolutionOptions = [
  {
    label: 'None',
    value: '',
  },
  {
    label: 'Estimated',
    value: 'use_estimated',
  },
  {
    label: 'Reported',
    value: 'use_reported',
  },
];

export const bunkerDirectory = [
  // { label: 'HFO (HS)', value: 'hfo_hs' }, // TODO: Consider removing when bunker types are updated
  // { label: 'HFO (VLSFO)', value: 'hfo_vls' }, // TODO: Consider removing when bunker types are updated
  // { label: 'LFO (VLFSO)', value: 'lfo_vls' }, // TODO: Consider removing when bunker types are updated
  // { label: 'LFO (ULSFO)', value: 'lfo_uls' }, // TODO: Consider removing when bunker types are updated
  // { label: 'MGO (ULS)', value: 'mgo_uls' }, // TODO: Consider removing when bunker types are updated
  { label: 'HFO', value: 'hfo' },
  { label: 'LFO', value: 'lfo' },
  { label: 'MGO', value: 'mgo' },
  { label: 'LNG', value: 'lng' },
  { label: 'Propane', value: 'propane' },
  { label: 'Butane', value: 'butane' },
  { label: 'Ethane', value: 'ethane' },
  { label: 'Methanol', value: 'methanol' },
  { label: 'ULSFO', value: 'ulsfo' },
  { label: 'VLSFO', value: 'vlsfo' },
  { label: 'LSFO', value: 'lsfo' },
  { label: 'IFO', value: 'ifo' },
  { label: 'LSMGO', value: 'lsmgo' },
  { label: 'MDO', value: 'mdo' },
];

export const reportTypes = [
  {
    label: 'CAST OFF',
    value: 'cast_off',
  },
  {
    label: 'COSP',
    value: 'cosp',
  },
  {
    label: 'NOON',
    value: 'noon',
  },
  {
    label: 'EOSP',
    value: 'eosp',
  },
  {
    label: 'ALL FAST',
    value: 'all_fast',
  },
  {
    label: 'SPECIAL EVENT START',
    value: 'special_event_start',
  },
  {
    label: 'SPECIAL EVENT END',
    value: 'special_event_end',
  },
];

export const locations = [
  {
    label: 'At sea',
    value: 'at_sea',
  },
  {
    label: 'In port/area',
    value: 'in_port',
  },
  {
    label: 'At berth',
    value: 'at_berth',
  },
  {
    label: 'STS within port limits',
    value: 'sts_within_port_limits',
  },
  {
    label: 'STS outside port limits',
    value: 'sts_outside_port_limits',
  },
];

export const shipConditions = [
  {
    label: 'Ballast',
    value: 'ballast',
  },
  {
    label: 'Laden',
    value: 'laden',
  },
];

export const operationModes = [
  {
    value: 'ice',
    label: 'Ice',
  },
  {
    value: 'emergency',
    label: 'Emergency',
  },
];

export const portActivities = [
  {
    label: 'Loading',
    value: 'loading',
  },
  {
    label: 'Discharging',
    value: 'discharging',
  },
  {
    label: 'Bunkering',
    value: 'bunkering',
  },
  {
    label: 'Repair',
    value: 'repair',
  },
  {
    label: 'Drydock',
    value: 'drydock',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const altPortActivities = [
  {
    label: 'Loading',
    value: 'loading',
  },
  {
    label: 'Discharging',
    value: 'discharging',
  },
  {
    label: 'Bunkering',
    value: 'bunkering',
  },
  {
    label: 'Anchored/Drifting/Maneuvering',
    value: 'idle',
  },
  {
    label: 'Canal Transit',
    value: 'canal_transit',
  },
  {
    label: 'Repair',
    value: 'repair',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const allPortActivities = [
  {
    label: 'Loading',
    value: 'loading',
  },
  {
    label: 'Discharging',
    value: 'discharging',
  },
  {
    label: 'Bunkering',
    value: 'bunkering',
  },
  {
    label: 'Repair',
    value: 'repair',
  },
  {
    label: 'Drydock',
    value: 'drydock',
  },
  {
    label: 'Anchored/Drifting/Manoeuvring',
    value: 'idle',
  },
  {
    label: 'Canal Transit',
    value: 'canal_transit',
  },
  {
    label: 'Other',
    value: 'other',
  },
];

export const sourceOptions = [
  {
    label: 'Siglar',
    value: 'siglar',
  },
  {
    label: 'UNECE',
    value: 'unece',
  },
];

export const timezones = [
  {
    value: '-12:00',
    label: 'UTC-12:00',
  },
  {
    value: '-11:00',
    label: 'UTC-11:00',
  },
  {
    value: '-10:00',
    label: 'UTC-10:00',
  },
  {
    value: '-09:30',
    label: 'UTC-09:30',
  },
  {
    value: '-09:00',
    label: 'UTC-09:00',
  },
  {
    value: '-08:00',
    label: 'UTC-08:00',
  },
  {
    value: '-07:00',
    label: 'UTC-07:00',
  },
  {
    value: '-06:00',
    label: 'UTC-06:00',
  },
  {
    value: '-05:00',
    label: 'UTC-05:00',
  },
  {
    value: '-04:00',
    label: 'UTC-04:00',
  },
  {
    value: '-03:30',
    label: 'UTC-03:30',
  },
  {
    value: '-03:00',
    label: 'UTC-03:00',
  },
  {
    value: '-02:30',
    label: 'UTC-02:30',
  },
  {
    value: '-02:00',
    label: 'UTC-02:00',
  },
  {
    value: '-01:00',
    label: 'UTC-01:00',
  },
  {
    value: '+00:00',
    label: 'UTC±00:00',
  },
  {
    value: '+01:00',
    label: 'UTC+01:00',
  },
  {
    value: '+02:00',
    label: 'UTC+02:00',
  },
  {
    value: '+03:00',
    label: 'UTC+03:00',
  },
  {
    value: '+03:30',
    label: 'UTC+03:30',
  },
  {
    value: '+04:00',
    label: 'UTC+04:00',
  },
  {
    value: '+04:30',
    label: 'UTC+04:30',
  },
  {
    value: '+05:00',
    label: 'UTC+05:00',
  },
  {
    value: '+05:30',
    label: 'UTC+05:30',
  },
  {
    value: '+05:45',
    label: 'UTC+05:45',
  },
  {
    value: '+06:00',
    label: 'UTC+06:00',
  },
  {
    value: '+06:30',
    label: 'UTC+06:30',
  },
  {
    value: '+07:00',
    label: 'UTC+07:00',
  },
  {
    value: '+08:00',
    label: 'UTC+08:00',
  },
  {
    value: '+08:45',
    label: 'UTC+08:45',
  },
  {
    value: '+09:00',
    label: 'UTC+09:00',
  },
  {
    value: '+09:30',
    label: 'UTC+09:30',
  },
  {
    value: '+10:00',
    label: 'UTC+10:00',
  },
  {
    value: '+10:30',
    label: 'UTC+10:30',
  },
  {
    value: '+11:00',
    label: 'UTC+11:00',
  },
  {
    value: '+12:00',
    label: 'UTC+12:00',
  },
  {
    value: '+12:45',
    label: 'UTC+12:45',
  },
  {
    value: '+13:00',
    label: 'UTC+13:00',
  },
  {
    value: '+14:00',
    label: 'UTC+14:00',
  },
];
