/* src/app/shared/components/confirmation-dialog/confirmation-dialog.component.scss */
.dialog-body {
  color: var(--sc-color-light);
  height: 250px;
  padding: 16px;
  overflow-y: scroll;
}
.dialog-body::-webkit-scrollbar {
  display: none;
}
/*# sourceMappingURL=confirmation-dialog.component.css.map */
