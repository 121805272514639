import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';
import * as fromRoot from '../actions/core.actions';
import { loadShips } from '../actions/ship.actions';

@Injectable({ providedIn: 'root' })
export class CoreEffects {
  constructor(private actions$: Actions) {}

  initialize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromRoot.initialize),
      map(() => loadShips()),
    ),
  );
}
