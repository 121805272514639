import { ExcelReport } from 'src/types';

export const voyageStatus = ['Not started', 'Underway', 'Completed', 'Verified'];

export const voyageSortOptions = [
  {
    value: 'ship_name:asc',
    label: 'Name (ascending)',
  },
  {
    value: 'ship_name:desc',
    label: 'Name (descending)',
  },
  {
    value: 'cpDate:asc',
    label: 'CP date (oldest)',
  },
  {
    value: 'cpDate:desc',
    label: 'CP date (latest)',
  },
  {
    value: 'lastReportReceived:asc',
    label: 'Last report received (oldest)',
  },
  {
    value: 'lastReportReceived:desc',
    label: 'Last report received (latest)',
  },
  {
    value: 'lastModified:asc',
    label: 'Last modified (oldest)',
  },
  {
    value: 'lastModified:desc',
    label: 'Last modified (latest)',
  },
  {
    value: 'created:asc',
    label: 'Created (oldest)',
  },
  {
    value: 'created:desc',
    label: 'Created (latest)',
  },
  {
    value: 'verified:asc',
    label: 'Verified (oldest)',
  },
  {
    value: 'verified:desc',
    label: 'Verified (latest)',
  },
];

export const voyageTagsOptions = [
  {
    value: 'coa',
    label: 'COA',
  },
  {
    value: 'spot',
    label: 'SPOT',
  },
  {
    value: 'tc',
    label: 'TC',
  },
];

export const defaultTemplateColumns = [
  'reportDate',
  'localTime',
  'utcOffset',
  'reportType',
  'port',
  'sailedDistanceNm',
  'cargoQuantityMT',
  'primaryRob',
  'primaryCons',
  'secondaryRob',
  'secondaryCons',
  'tertiaryRob',
  'tertiaryCons',
  'remark',
] as (keyof ExcelReport)[];

export const defaultTemplateColumnsV3 = [
  'reportDate',
  'localTime',
  'utcOffset',
  'reportType',
  'port',
  'nextCargoPort',
  'sailedDistanceNm',
  'cargoQuantityMT',
  'speedOverGroundKT',
  'latitude',
  'longitude',
  'primaryRob',
  'primaryCons',
  'primaryRcv',
  'secondaryRob',
  'secondaryCons',
  'secondaryRcv',
  'tertiaryRob',
  'tertiaryCons',
  'tertiaryRcv',
  'remark',
] as (keyof ExcelReport)[];

export const defaultTemplateColumnsV4 = [
  'reportDate',
  'localTime',
  'utcOffset',
  'reportType',
  'port',
  'cargoQuantityMT',
  'location',
  'previousCargoPort',
  'nextCargoPort',
  'sailedDistanceNm',
  'speedOverGroundKT',
  'latitude',
  'longitude',
  'primaryRob',
  'primaryCons',
  'primaryRcv',
  'secondaryRob',
  'secondaryCons',
  'secondaryRcv',
  'tertiaryRob',
  'tertiaryCons',
  'tertiaryRcv',
  'remark',
] as (keyof ExcelReport)[];

export const defaultTemplateColumnsV5 = [
  'reportDate',
  'localTime',
  'utcOffset',
  'reportType',
  'port',
  'cargoQuantityMT',
  'location',
  'previousCargoPort',
  'nextCargoPort',
  'sailedDistanceNm',
  'speedOverGroundKT',
  'latitude',
  'longitude',
  'primaryRob',
  'primaryCons',
  'primaryRcv',
  'secondaryRob',
  'secondaryCons',
  'secondaryRcv',
  'tertiaryRob',
  'tertiaryCons',
  'tertiaryRcv',
  'remark',
  'speedThroughWaterKT',
  'cargoQuantityM3',
  'timeSinceLastReport',
  'timeAtAnchor',
  'timeDrifting',
  'primaryHeatingBoilerMT',
  'secondaryHeatingBoilerMT',
  'tertiaryHeatingBoilerMT',
  'primaryDischargeBoilerMT',
  'secondaryDischargeBoilerMT',
  'tertiaryDischargeBoilerMT',
  'primaryDischargeStandaloneMT',
  'secondaryDischargeStandaloneMT',
  'tertiaryDischargeStandaloneMT',
  'dischargeKWH',
  'dischargeSFOCGKWH',
  'coolingKWH',
  'coolingSFOCGKWH',
] as (keyof ExcelReport)[];

export const defaultTemplateColumnsV5_1 = [
  'reportDate',
  'localTime',
  'utcOffset',
  'reportType',
  'port',
  'cargoQuantityMT',
  'location',
  'previousCargoPort',
  'nextCargoPort',
  'sailedDistanceNm',
  'speedOverGroundKT',
  'latitude',
  'longitude',
  'primaryRob',
  'primaryCons',
  'primaryRcv',
  'secondaryRob',
  'secondaryCons',
  'secondaryRcv',
  'tertiaryRob',
  'tertiaryCons',
  'tertiaryRcv',
  'mainEngineConsumptionMT',
  'auxConsumptionMT',
  'boilerConsumptionMT',
  'inertGasGeneratorConsumptionMT',
  'gasCombustionUnitConsumptionMT',
  'remark',
  'speedThroughWaterKT',
  'cargoQuantityM3',
  'timeSinceLastReport',
  'timeAtAnchor',
  'timeDrifting',
  'primaryHeatingBoilerMT',
  'secondaryHeatingBoilerMT',
  'tertiaryHeatingBoilerMT',
  'primaryDischargeBoilerMT',
  'secondaryDischargeBoilerMT',
  'tertiaryDischargeBoilerMT',
  'primaryDischargeStandaloneMT',
  'secondaryDischargeStandaloneMT',
  'tertiaryDischargeStandaloneMT',
  'dischargeKWH',
  'dischargeSFOCGKWH',
  'coolingKWH',
  'coolingSFOCGKWH',
] as (keyof ExcelReport)[];

export const anomaliesTypes = [
  { name: 'All anomalies', value: '' },
  { name: 'Consumption', value: 'consumption' },
  { name: 'Distance', value: 'distance' },
  { name: 'Port distance', value: 'port_distance' },
];

export const voyageStatusList = [
  {label: 'All status', value: ''},
  {label: 'Not started', value: 'not_started'},
  {label: 'Not cancelled', value: 'not_cancelled'},
  {label: 'Underway', value: 'underway'},
  {label: 'Completed', value: 'completed'},
  {label: 'Assumed Ended', value: 'assumed_ended'}
];

export const sortOptions = [
  { name: 'Name (ascending)', value: 'asc' },
  { name: 'Name (descending)', value: 'desc' },
  { name: 'Cp date (oldest)', value: 'oldest' },
  { name: 'Cp date (newest)', value: 'newest' },
];

export const VerifiableErrors = [
  'noLoadingPort',
  'noDischargingPort',
  'wrongNextCargoPort',
  'wrongPreviousCargoPort',
  'inconsistentROBandCons',
  'missingAtLeastOneCastOffReportWithLaden',
];
