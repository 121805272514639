import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastrService } from 'ngx-toastr';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import * as fromAuth from '../actions/auth.actions';
import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthEffects {
  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
  ) {}

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuth.login),
      exhaustMap(() =>
        this.authService.login().pipe(
          map(() => fromAuth.getAuth0Token()),
          catchError((error) => of(fromAuth.loginFailure({ error }))),
        ),
      ),
    ),
  );

  getAuth0Token$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuth.getAuth0Token),
      exhaustMap(() =>
        this.authService.getAccessToken().pipe(
          map((token) => {
            this.authService.setAuth(token);
            return fromAuth.getAuthenticatedUser();
          }),
          catchError(() => of(fromAuth.loginRedirect({ returnUrl: '/voyages/active' }))),
        ),
      ),
    ),
  );

  getAuthenticatedUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAuth.getAuthenticatedUser),
      exhaustMap(() =>
        this.authService.getAuthenticatedUser().pipe(
          map((user) => {
            if (user.roles.includes('role:admin')) {
              return fromAuth.getAuthenticatedUserSuccess({ user });
            }
            return fromAuth.getAuthenticatedUserFailure({ error: 'User is not Siglar Admin' });
          }),
          catchError(({ error }) => of(fromAuth.getAuthenticatedUserFailure(error))),
        ),
      ),
    ),
  );

  getSiglarUserFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuth.getAuthenticatedUserFailure),
        tap(({ type, error }) => {
          this.toastr.error(error ? error : 'Something went wrong', type, { timeOut: 3000 });
          return fromAuth.logout();
        }),
      ),
    { dispatch: false },
  );

  loginRedirect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuth.loginRedirect),
        map(({ returnUrl }) =>
          this.authService.loginWithRedirect(returnUrl).pipe(
            map(() => fromAuth.getAuth0Token()),
            catchError((error) => of(fromAuth.loginFailure({ error }))),
          ),
        ),
      ),
    { dispatch: false },
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuth.logout),
        map(() => this.authService.logout()),
        tap(() => this.router.navigate(['/auth'])),
      ),
    { dispatch: false },
  );
}
