import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { SiglarUser } from 'src/types';
import * as fromUsers from '../actions/users.actions';
import { UserService } from '../services/users.service';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/shared/components/confirmation-dialog/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({ providedIn: 'root' })
export class UserEffects {
  constructor(
    private actions$: Actions,
    private userService: UserService,
    private _dialog: MatDialog,
    private toastr: ToastrService,
    private store: Store<any>,
  ) {}

  loadUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        fromUsers.loadUsers,
        fromUsers.createUserSuccess,
        fromUsers.updateUserSuccess,
        fromUsers.deleteUserSuccess,
      ),
      switchMap(() =>
        this.userService.loadUsers().pipe(
          map(({ body: { complete: users } }) => fromUsers.loadUsersSuccess({ users })),
          catchError(({ error }) => of(fromUsers.loadUsersFailure({ error }))),
        ),
      ),
    ),
  );

  loadOpsUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUsers.loadOpsUsers),
      switchMap(() =>
        this.userService.loadOpsUsers().pipe(
          map(({ body: { complete: users } }) => fromUsers.loadOpsUsersSuccess({ users })),
          catchError(({ error }) => of(fromUsers.loadOpsUsersFailure({ error }))),
        ),
      ),
    ),
  );

  createUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUsers.createUser),
      switchMap((user) =>
        this.userService.createUser(user).pipe(
          map((response) => fromUsers.createUserSuccess({ response })),
          catchError(({ error }) => of(fromUsers.createUserFailure({ error }))),
        ),
      ),
    ),
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUsers.updateUser),
      switchMap((user) =>
        this.userService.updateUser(user).pipe(
          map((response) => fromUsers.updateUserSuccess({ response })),
          catchError(({ error }) => of(fromUsers.updateUserFailure({ error }))),
        ),
      ),
    ),
  );

  deleteUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromUsers.deleteUser),
      switchMap(({ email }) =>
        this.userService.deleteUser(email).pipe(
          map((response) => fromUsers.deleteUserSuccess({ response })),
          catchError(({ error }) => of(fromUsers.deleteUserFailure({ error }))),
        ),
      ),
    ),
  );

  openDeleteUserPrompt$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromUsers.openDeleteUserPrompt),
        tap((user: SiglarUser) => {
          const { email, company, name } = user;

          this._dialog
            .open(ConfirmationDialogComponent, {
              disableClose: true,
              width: '600px',
              height: '400px',
              data: {
                title: 'Confirm delete company',
                message: `
              <p>Are you sure you want to delete the following user?</p>
              <p><span class="color-light">Name:</span> ${name}</p>
              <p><span class="color-light">Email:</span> ${email}</p>
              <p><span class="color-light">Company:</span> ${company}</p>
            `,
                btnOkText: 'Delete user',
              },
            })
            .afterClosed()
            .subscribe((data: boolean) => {
              if (data) {
                this.store.dispatch(fromUsers.deleteUser({ email }));
              }
            });
        }),
      ),
    { dispatch: false },
  );

  success$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromUsers.updateUserSuccess, fromUsers.createUserSuccess, fromUsers.deleteUserSuccess),
        tap(({ type }) => this.toastr.success('The operation was successful!', type, { timeOut: 3000 })),
      ),
    { dispatch: false },
  );

  error$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          fromUsers.updateUserFailure,
          fromUsers.createUserFailure,
          fromUsers.deleteUserFailure,
          fromUsers.loadUsersFailure,
        ),
        tap(({ type, error }) => {
          const message = error?.error ? error?.errorDetail || error?.error : 'Something went wrong';
          this.toastr.error(message, type, { timeOut: 5000 });
        }),
      ),
    { dispatch: false },
  );
}
