import { createSelector } from '@ngrx/store';
import { AppState } from '..';
import { compare } from 'src/utils';

export const selectportsState = (state: AppState) => state.ports;

export const selectPorts = createSelector(selectportsState, ({ ports }) => ports);

export const selectCountries = createSelector(selectportsState, ({ countries }) => countries);

export const selectFilteredPorts = createSelector(
  selectportsState,
  ({ entries, filters: { searchValue, sortBy, isAsc } }) =>
    entries
      .filter((port) =>
        Object.values(port).some((entry) => String(entry)?.toLowerCase().includes(searchValue.toLowerCase()))
      )
      .sort((a, b) => compare(String(a[sortBy]), String(b[sortBy]), isAsc))
);

export const selectPaginatedPorts = createSelector(
  selectportsState,
  selectFilteredPorts,
  ({ filters: { startIndex, endIndex } }, ports) => (!!endIndex ? ports.slice(startIndex, endIndex) : ports),
);

export const selectPortSuggestions = createSelector(selectportsState, ({ entries, filters: { searchSuggestion } }) => {
  const suggestions: string[] = [];

  if (searchSuggestion.length) {
    entries.forEach((port) => {
      const portValue = `${port.name}:${port.unlocode}`;
      const lowercasedName = port.name?.toLowerCase();
      const lowercasedUnlocode = port.unlocode?.toLowerCase();
      const lowercasedCountry = port.country?.toLowerCase();
      const lowercasedSearchSuggestion = searchSuggestion.toLowerCase();

      const isNameMatch = lowercasedName?.includes(lowercasedSearchSuggestion);
      const isUnlocodeMatch = lowercasedUnlocode?.includes(lowercasedSearchSuggestion);
      const isCountryMatch = lowercasedCountry?.includes(lowercasedSearchSuggestion);
      if ((isNameMatch || isUnlocodeMatch) && !suggestions.includes(portValue)) {
        suggestions.push(portValue);
      }

      if (isCountryMatch && !suggestions.includes(port.country)) {
        suggestions.push(port.country);
      }
    });

    return suggestions
      .sort((a, b) => b.localeCompare(searchSuggestion) - a.localeCompare(searchSuggestion))
      .slice(0, 5);
  } else {
    return suggestions;
  }
});

export const selectPortsLoading = createSelector(selectportsState, ({ loading }) => loading);

export const selectPortFilters = createSelector(selectportsState, selectFilteredPorts, ({ filters }, ports) => ({
  ...filters,
  totalPorts: ports.length,
}));

export const getPortById = (portId: string) => createSelector(selectportsState, ({ ports }) => ports?.get(portId));
