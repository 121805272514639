import { concatLatestFrom } from '@ngrx/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { DashboardService } from '../services/dashboard.service';
import * as fromDashboard from '../actions/dashboard.actions';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { getAppliedFilters } from 'src/utils';
import { Store } from '@ngrx/store';
import { selectDashboardFilter } from '../selectors/dashboard.selectors';

@Injectable()
export class DashboardEffects {
  constructor(
    private actions$: Actions,
    private dashboardService: DashboardService,
    private store: Store<any>,
  ) {}

  loadDashboardVoyages$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromDashboard.loadDashboardVoyages),
      switchMap(({ filters }) =>
        this.dashboardService.getDashboardVoyages(filters).pipe(
          map(({ data }) => fromDashboard.loadDashboardVoyagesSuccess({ data })),
          catchError((error) => of(fromDashboard.loadDashboardVoyagesFailure({ error }))),
        ),
      ),
    ),
  );

  loadDashboardCompanies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromDashboard.loadDashboardCompanies),
      switchMap(({ filters }) =>
        this.dashboardService.getDashboardCompanies(filters).pipe(
          map(({ data }) => fromDashboard.loadDashboardCompaniesSuccess({ data })),
          catchError((error) => of(fromDashboard.loadDashboardCompaniesFailure({ error }))),
        ),
      ),
    ),
  );

  loadDashboardAnomalies$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromDashboard.loadDashboardAnomalies),
      switchMap(({ filters }) =>
        this.dashboardService.getDashboardAnomalies(filters).pipe(
          map((data) => fromDashboard.loadDashboardAnomaliesSuccess({ data })),
          catchError((error) => of(fromDashboard.loadDashboardAnomaliesFailure({ error }))),
        ),
      ),
    ),
  );

  setDashboardFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromDashboard.setDashboardFilters),
      concatLatestFrom(() => this.store.select(selectDashboardFilter)),
      switchMap(([{ filters: dashboardFilters, loadCount }, currentFilters]) => {
        const filters = getAppliedFilters({ ...currentFilters, ...dashboardFilters });
        if (loadCount) {
          this.store.dispatch(fromDashboard.loadDashboardVoyages({ filters }));
          this.store.dispatch(fromDashboard.loadDashboardCompanies({ filters }));
        }
        return of(fromDashboard.loadDashboardAnomalies({ filters }));
      }),
    ),
  );
}
